<script>
  // You can pass any props or control logic here if needed.
  // For now, there's no need for JavaScript logic in this component.
</script>

<div class="lore-text">
  <p class="disclaimer">
    Disclaimer: Welcome to our high magic fantasy tabletop RPG! We strive to
    create a fun and inclusive environment for everyone. If you have any
    specific concerns or content worries, please reach out to me (Chris Mckenzie
    '@chrisalterradm') directly.
  </p>
  <h3>Previously on Eres</h3>
  <p>
    Eres, once home to four powerful city-states, is now a war-torn landscape.
    Akiba, a small trading town, has grown into a city-state and stands as the
    last sanctuary of peace. After three years of war, divine armies have been
    routed, but new threats are emerging, including undead, deadly plants, and
    monstrous creatures. Adventurers are needed to clear these threats and
    reclaim lost territories.
  </p>

  <h4>Key Terms:</h4>
  <ul>
    <li>
      <b>Akiba:</b> A thriving city-state and the last sanctuary of peace.
    </li>
    <li>
      <b>Ragnar:</b> The Divine Plane of War, home to the War Gods and their armies.
    </li>
    <li>
      <b>Bloodraven:</b> A city once ruled by Lord Black, now fallen to the Ragnar
      Crusade.
    </li>
    <li>
      <b>Salarin:</b> A city of magic, once ruled by a Fire Genasi family, now fallen
      after a Kaiju attack.
    </li>
    <li>
      <b>Highgarden & Undergarden:</b> Elven cities that fell during the Ragnar Crusade.
      Elves now contribute greatly to Akiba.
    </li>
    <li>
      <b>Steamforge:</b> A steampunk city built in an active volcano, vital for Akiba’s
      defense.
    </li>
    <li>
      <b>The Murder Isles:</b> Home to Kenku and famous for Bardic Colleges, unaffected
      by the Ragnar Crusade.
    </li>
    <li>
      <b>The Karndar Mountains:</b> A kingdom of Grey Cyberpunk Dwarves, in hostile
      isolation.
    </li>
    <li>
      <b>The Sunrise Expanse:</b> Distant lands inhabited by roaming Dragonborn tribes.
    </li>
    <li>
      <b>The Council of Ten:</b> The governing body of Akiba, formed during the Ragnar
      Crusade.
    </li>
    <li>
      <b>The Dragonfruit Barons:</b> A wealthy faction controlling much of Akiba's
      economy.
    </li>
    <li>
      <b>B.S. Die Right:</b> A mysterious figure involved in the Anchorage Station
      Project.
    </li>
    <li>
      <b>Gaian Royal Family:</b> The ruling body of Steamforge, with King Arcan and
      Princess Nadia Arcan leading the way in the development of a railway.
    </li>
    <li><b>Gibblet:</b> [James to provide summary]</li>
    <li><b>Lycaster:</b> [James to provide summary]</li>
  </ul>
</div>

<style>
  * {
    box-sizing: border-box;
  }

  .disclaimer {
    margin-bottom: 50px;
    color: lightblue;
  }

  .lore-text {
    padding: 50px;
    color: white;
    text-align: center;
  }

  .lore-text p {
    text-align: justify;
  }

  .lore-text h4 {
    text-align: left;
    margin-top: 50px;
  }

  .lore-text li {
    text-align: left;
    margin-left: 15px;
    margin-bottom: 25px;
    list-style-type: none;
  }

  @media (max-width: 768px) {
    .lore-text {
      padding: 20px;
    }

    .lore-text h4 {
      margin-top: 20px;
    }
  }
</style>
