<script>
  import EditQuestModal from "../lib/components/EditQuestModal.svelte";
  import NewQuestModal from "../lib/components/NewQuestModal.svelte";
  import { onMount } from "svelte";
  import { auth } from "../lib/stores/auth";
  import { get } from "svelte/store";
  import RedBaron from "../lib/components/Season2025P1/RedBaron.svelte";
  import BlueBaron from "../lib/components/Season2025P1/BlueBaron.svelte";
  import GreenBaron from "../lib/components/Season2025P1/GreenBaron.svelte";
  import BlackBaron from "../lib/components/Season2025P1/BlackBaron.svelte";
  import WhiteBaron from "../lib/components/Season2025P1/WhiteBaron.svelte";
  import Season from "../lib/components/Season2025P1/Season.svelte";
  import Rules from "../lib/components/Season2025P1/Rules.svelte";

  $: tab = "rules";
  let maps = [
    "media/images/Eres.png",
    "media/images/Geni.png",
    "media/images/Phantasia.png",
    "media/images/Amfore.png",
  ];
  let selectedMap = maps[0];

  let page_tabs = [
    { label: "Rules", tab: "rules", borderClass: "grey-border" },
    {
      label: "Season Storyline",
      tab: "season",
      borderClass: "grey-border",
    },
    { label: "Red", tab: "red", borderClass: "red-border" },
    { label: "Blue", tab: "blue", borderClass: "blue-border" },
    { label: "Green", tab: "green", borderClass: "green-border" },
    { label: "White", tab: "white", borderClass: "white-border" },
    { label: "Black", tab: "black", borderClass: "black-border" },
    {
      label: "Maps",
      tab: "maps",
      borderClass: "grey-border",
    },
    {
      label: "Quest Log",
      tab: "questlog",
      borderClass: "grey-border",
    },
  ];

  // Quest Logic
  let event_quests = [];
  let character_quests = [];
  let side_quests = [];
  let completed_quests = [];

  // Accordion Logic
  let showEventQuests = true;
  let showCharacterQuests = false;
  let showSideQuests = false;
  let showCompletedQuests = false;

  // Editor Logic
  const authState = get(auth);
  const dm = authState.isAuthenticated && authState.user?.dm;
  const username = authState.user?.username;

  let editInProgress = false;
  let newInProgress = false;
  let selectedQuest = {};

  // Snackbar logic
  let snackbarMessage = "";
  let showSnackbar = false;

  function toggleEventQuests() {
    showEventQuests = !showEventQuests;
  }

  function toggleCharacterQuests() {
    showCharacterQuests = !showCharacterQuests;
  }

  function toggleSideQuests() {
    showSideQuests = !showSideQuests;
  }

  function toggleCompletedQuests() {
    showCompletedQuests = !showCompletedQuests;
  }

  function changeTab(newTab) {
    tab = newTab;
  }

  function changeMap(newMap) {
    selectedMap = newMap;
  }

  async function fetchQuests() {
    try {
      const response = await fetch(`/api/quests`);
      if (response.ok) {
        const quest_data = await response.json();
        event_quests = quest_data.filter((quest) => quest.category === "event");
        character_quests = quest_data.filter(
          (quest) => quest.category === "character"
        );
        side_quests = quest_data.filter((quest) => quest.category === "side");
        completed_quests = quest_data.filter(
          (quest) => quest.category === "complete"
        );
      } else {
        console.error("Failed to fetch data from the API");
      }
    } catch (error) {
      console.error("Error: ", error);
    }
  }

  function newQuest() {
    newInProgress = true;
  }

  function editQuest(quest) {
    selectedQuest = quest;
    editInProgress = true;
  }

  function closeEditModal() {
    editInProgress = false;
    newInProgress = false;
    selectedQuest = {};
  }

  function closeNewQuestModal() {
    newInProgress = false;
    snackbarMessage = "Quest added successfully!";
    showSnackbar = true;

    setTimeout(() => {
      fetchQuests();
      showSnackbar = false;
    }, 2000);
  }

  onMount(() => {
    fetchQuests();
  });
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<slot>
  <div class="page-container">
    <div class="sidebar">
      <div class="circle-gap"></div>
      {#each page_tabs as item}
        <button
          class="circle-button {item.borderClass} {tab === item.tab
            ? 'active'
            : ''} "
          on:click={() => changeTab(item.tab)}
        >
          {item.label}
        </button>
      {/each}
    </div>

    <div class="content-container">
      {#if tab === "maps"}
        <div class="mini-map">
          {#each maps as map}
            <button on:click={() => changeMap(map)}>
              <img src={map} alt={`Thumbnail of ${map}`} />
            </button>
          {/each}
        </div>
        <div class="big-map">
          <img src={selectedMap} alt="Selected Map" class="map-image" />
        </div>
      {:else if tab === "rules"}
        <Rules />
      {:else if tab === "season"}
        <Season />
      {:else if tab === "red"}
        <RedBaron />
      {:else if tab === "blue"}
        <BlueBaron />
      {:else if tab === "green"}
        <GreenBaron />
      {:else if tab === "white"}
        <WhiteBaron />
      {:else if tab === "black"}
        <BlackBaron />
      {:else if tab === "questlog"}
        <div class="quest-text">
          <h3>Quest Board</h3>

          {#if dm}
            <button
              on:click={() => newQuest()}
              class={editInProgress || newInProgress
                ? "edit-disabled"
                : "edit-button"}
              disabled={editInProgress || newInProgress}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-plus"
              >
                <path d="M12 5v14M5 12h14" />
              </svg></button
            >
          {/if}

          <div class="accordion">
            <h4 on:click={toggleEventQuests}>
              Event Quests[{event_quests.length}]
            </h4>
            {#if showEventQuests}
              {#each event_quests as quest}
                <div class="questcard">
                  <h4>
                    {quest.title}{#if dm && username === quest?.username}
                      <button
                        on:click={() => editQuest(quest)}
                        class={editInProgress || newInProgress
                          ? "edit-disabled"
                          : "edit-button"}
                        disabled={editInProgress || newInProgress}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "white"}
                          />
                          <path
                            d="M20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L14.85 5.4L18.6 9.15L20.71 7.04Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "green"}
                          />
                        </svg>
                      </button>{/if}
                  </h4>
                  <p><b>Game Master: </b>{quest?.username}</p>
                  <p><b>Sponsor: </b>{quest?.sponsor}</p>
                  <p><b>Summary: </b>{quest?.summary}</p>
                  <p><b>Reward: </b>{quest?.reward}</p>
                  <p>
                    <b>Gameplay Tags: </b>
                    {quest.gameplay_tags && quest.gameplay_tags.length > 0
                      ? quest.gameplay_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Location Tags: </b>
                    {quest.location_tags && quest.location_tags.length > 0
                      ? quest.location_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Character Tags: </b>
                    {quest.character_tags && quest.character_tags.length > 0
                      ? quest.character_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Theme Tags: </b>
                    {quest.theme_tags && quest.theme_tags.length > 0
                      ? quest.theme_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p><b>DM Inspiration: </b>{quest?.inspiration}</p>
                </div>
              {/each}
            {/if}
          </div>

          <div class="accordion">
            <h4 on:click={toggleCharacterQuests}>
              Character Quests [{character_quests.length}]
            </h4>
            {#if showCharacterQuests}
              {#each character_quests as quest}
                <div class="questcard">
                  <h4>
                    {quest.title}{#if dm && username === quest?.username}
                      <button
                        on:click={() => editQuest(quest)}
                        class={editInProgress || newInProgress
                          ? "edit-disabled"
                          : "edit-button"}
                        disabled={editInProgress || newInProgress}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "white"}
                          />
                          <path
                            d="M20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L14.85 5.4L18.6 9.15L20.71 7.04Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "green"}
                          />
                        </svg>
                      </button>{/if}
                  </h4>
                  <p><b>Game Master: </b>{quest?.username}</p>
                  <p><b>Sponsor: </b>{quest?.sponsor}</p>
                  <p><b>Summary: </b>{quest?.summary}</p>
                  <p><b>Reward: </b>{quest?.reward}</p>
                  <p>
                    <b>Gameplay Tags: </b>
                    {quest.gameplay_tags && quest.gameplay_tags.length > 0
                      ? quest.gameplay_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Location Tags: </b>
                    {quest.location_tags && quest.location_tags.length > 0
                      ? quest.location_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Character Tags: </b>
                    {quest.character_tags && quest.character_tags.length > 0
                      ? quest.character_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Theme Tags: </b>
                    {quest.theme_tags && quest.theme_tags.length > 0
                      ? quest.theme_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p><b>DM Inspiration: </b>{quest?.inspiration}</p>
                </div>
              {/each}
            {/if}
          </div>

          <div class="accordion">
            <h4 on:click={toggleSideQuests}>
              Side Quests [{side_quests.length}]
            </h4>
            {#if showSideQuests}
              {#each side_quests as quest}
                <div class="questcard">
                  <h4>
                    {quest.title}{#if dm && username === quest?.username}
                      <button
                        on:click={() => editQuest(quest)}
                        class={editInProgress || newInProgress
                          ? "edit-disabled"
                          : "edit-button"}
                        disabled={editInProgress || newInProgress}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "white"}
                          />
                          <path
                            d="M20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L14.85 5.4L18.6 9.15L20.71 7.04Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "green"}
                          />
                        </svg>
                      </button>{/if}
                  </h4>
                  <p><b>Game Master: </b>{quest?.username}</p>
                  <p><b>Sponsor: </b>{quest?.sponsor}</p>
                  <p><b>Summary: </b>{quest?.summary}</p>
                  <p><b>Reward: </b>{quest?.reward}</p>
                  <p>
                    <b>Gameplay Tags: </b>
                    {quest.gameplay_tags && quest.gameplay_tags.length > 0
                      ? quest.gameplay_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Location Tags: </b>
                    {quest.location_tags && quest.location_tags.length > 0
                      ? quest.location_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Character Tags: </b>
                    {quest.character_tags && quest.character_tags.length > 0
                      ? quest.character_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Theme Tags: </b>
                    {quest.theme_tags && quest.theme_tags.length > 0
                      ? quest.theme_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p><b>DM Inspiration: </b>{quest?.inspiration}</p>
                </div>
              {/each}
            {/if}
          </div>

          <div class="accordion">
            <h4 on:click={toggleCompletedQuests}>
              Completed Quests [{completed_quests.length}]
            </h4>
            {#if showCompletedQuests}
              {#each completed_quests as quest}
                <div class="questcard">
                  <h4>
                    [Completed!] <s>{quest.title}</s
                    >{#if dm && username === quest?.username}
                      <button
                        on:click={() => editQuest(quest)}
                        class={editInProgress || newInProgress
                          ? "edit-disabled"
                          : "edit-button"}
                        disabled={editInProgress || newInProgress}
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "white"}
                          />
                          <path
                            d="M20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L14.85 5.4L18.6 9.15L20.71 7.04Z"
                            fill={editInProgress || newInProgress
                              ? "none"
                              : "green"}
                          />
                        </svg>
                      </button>{/if}
                  </h4>
                  <p><b>Game Master: </b>{quest?.username}</p>
                  <p><b>Sponsor: </b>{quest?.sponsor}</p>
                  <p><b>Summary: </b>{quest?.summary}</p>
                  <p><b>Reward: </b>{quest?.reward}</p>
                  <p style="margin-top:10px"><b>Outcome: </b>{quest.outcome}</p>
                  <p><b>Classified: </b>{quest.classified}</p>
                  <p>
                    <b>Gameplay Tags: </b>
                    {quest.gameplay_tags && quest.gameplay_tags.length > 0
                      ? quest.gameplay_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Location Tags: </b>
                    {quest.location_tags && quest.location_tags.length > 0
                      ? quest.location_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Character Tags: </b>
                    {quest.character_tags && quest.character_tags.length > 0
                      ? quest.character_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p>
                    <b>Theme Tags: </b>
                    {quest.theme_tags && quest.theme_tags.length > 0
                      ? quest.theme_tags.join(", ")
                      : "No tags available"}
                  </p>
                  <p><b>DM Inspiration: </b>{quest?.inspiration}</p>
                </div>
              {/each}
            {/if}
          </div>
        </div>
      {/if}
    </div>
  </div>
  {#if selectedQuest.id}
    <EditQuestModal
      show={editInProgress}
      quest={selectedQuest}
      onClose={closeEditModal}
    />
  {/if}
  {#if newInProgress}
    <NewQuestModal show={newInProgress} onClose={closeNewQuestModal} />
  {/if}

  <!-- Snackbar Notification -->
  {#if showSnackbar}
    <div class="snackbar">{snackbarMessage}</div>
  {/if}
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 15px;
    min-height: 95vh;
  }

  .sidebar {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    padding: 20px;
    padding-bottom: 0;
  }

  .circle-gap {
    margin-top: 20px;
  }

  .circle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 50px;
    background-color: #282d30;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    margin-top: 20px;
  }

  .circle-button.active {
    background-color: #ffcc70;
    color: #2a123f;
  }

  .orange-border {
    border: 2px solid rgba(255, 166, 0, 0.501);
  }

  .green-border {
    border: 2px solid #0ff11676;
  }

  .blue-border {
    border: 2px solid #0f9af175;
  }

  .red-border {
    border: 2px solid #f10f0f75;
  }

  .white-border {
    border: 2px solid #d0dae075;
  }

  .black-border {
    border: 2px solid #320000;
  }

  .grey-border {
    border: 2px solid #65644875;
  }

  .disclaimer {
    margin-bottom: 50px;
    color: lightblue;
  }

  .content-container {
    width: 100%;
    border: 10px solid #00000078;
    border-radius: 0 10px 10px 0;
    background: rgba(0, 0, 0, 0.453);
  }

  .content-container img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .lore-text {
    padding: 50px;
    color: white;
    text-align: center;
  }

  .quest-text {
    padding: 50px;
    color: white;
    text-align: center;
  }

  .quest-text h3 {
    margin-bottom: 40px;
  }

  .quest-text h4 {
    text-align: left;
    text-decoration: underline;
  }

  .questcard {
    color: white;
    text-align: center;
    background-color: rgba(36, 0, 47, 0.495);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    text-align: justify;
    margin-bottom: 20px;
  }

  .edit-button {
    border: none;
    cursor: pointer;
    padding: auto;
    margin-left: 4px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: rgba(36, 0, 47, 0.495);
  }

  .edit-disabled {
    border: none;
    padding: auto;
    margin-left: 4px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: rgba(36, 0, 47, 0.495);
  }

  .edit-button:hover {
    background-color: #073b20;
  }

  .mini-map {
    display: flex;
    gap: 10px;
  }

  .mini-map button {
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;
  }

  .mini-map img {
    width: 100px;
    height: 60px;
    object-fit: cover;
    border: 2px solid transparent;
    transition: border 0.3s;
  }

  .mini-map img:hover {
    border-color: #007bff;
  }

  .big-map {
    margin-top: 20px;
  }

  .big-map img {
    width: 100%;
    height: auto;
  }

  .snackbar {
    visibility: visible;
    min-width: 250px;
    background-color: #333;
    color: #fff;
    text-align: center;
    border-radius: 5px;
    padding: 16px;
    position: fixed;
    z-index: 9999;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);
    opacity: 0;
    animation: fadeInOut 4s ease-in-out;
  }

  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    .page-container {
      flex-direction: column;
    }

    .sidebar {
      width: 100%;
      padding: 10px;
      display: flex;
      border: none;
      background-color: transparent;
      justify-content: space-around;
    }

    .circle-button {
      height: 50px;
    }

    .content-container {
      border: none;
      background: transparent;
    }

    .lore-text,
    .quest-text {
      padding: 20px;
    }

    .quest-text h4 {
      margin-top: 20px;
    }

    .quest-text h3 {
      margin-bottom: 20px;
    }

    .questcard {
      padding: 10px;
      margin-bottom: 10px;
    }
  }
</style>
