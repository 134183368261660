<div class="lore-text">
  This file contains all known information on the Blue Baron.
</div>

<div class="dossier">
  <div class="dossier-header">
    <h1>Dossier: Blue Baron</h1>

    <div class="portrait">
      <img src="media/images/placeholder.png" alt="Blue Baron Portrait" />
    </div>
  </div>

  <div class="dossier-section">
    <h2>Profile</h2>
    <p>
      <strong>Name:</strong> The Blue Baron<br />
      <strong>Age:</strong> 43<br />
      <strong>Appearance:</strong> Hawk-faced half-elf with beady eyes and rectangular
      spectacles. Known for his sharp dress and calculated demeanor, always with
      an air of aloof intelligence.
    </p>
    <p>
      The Blue Baron is a master of secrets and leverage. As the only non-human
      among the Barons, his half-elf heritage and exceptional intelligence make
      him stand out. His insatiable envy drives his pursuit of influence and
      control. Known to oversee the flow of information through auction houses,
      media conglomerates, and high-end item dealings, he thrives in the world
      of espionage and blackmail. His methods are subtle but devastating,
      shaping narratives and exploiting his enemies' weaknesses.
    </p>
  </div>

  <div class="dossier-section">
    <h2>Public Enterprises</h2>
    <p>The Blue Baron manages several influential businesses, including:</p>
    <ul>
      <li>
        <strong>Azure Chronicle:</strong> A vast archive and vault empire, including
        hidden arcane-protected libraries, heavily trapped dungeons, and private
        intelligence services. Operates under the guise of a conservational endeavor
        while controlling the flow of uncovered secrets.
      </li>
      <li>
        <strong>Indigo Exchange:</strong> An exclusive auction house dealing in high-value
        items, including rare artifacts, secrets, and even stolen treasures. A hub
        for power players and the elite.
      </li>
      <li>
        <strong>Alliance with A'bal of Fris Conglomerate:</strong> An erstwhile pact
        that unified their control over media and communications. Currently under
        strain due to conflicting ambitions.
      </li>
    </ul>
  </div>

  <div class="dossier-section">
    <h2>Criminal Activities</h2>
    <p>
      Beneath the surface of his business ventures, the Blue Baron engages in a
      web of criminal and unethical activities:
    </p>
    <ul>
      <li>
        Deployed spies to plant false evidence against key societal figures,
        undermining their reputations and careers.
      </li>
      <li>
        Operates a blackmail network using sensitive information stolen from
        private archives.
      </li>
      <li>
        Sought unknown artifacts in the desert, including a dig site near the
        second half of the Sands of Time hourglass, but his focus was on
        something other than the hourglass itself.
      </li>
      <li>
        Manipulates markets and auctions by fabricating demand for high-end
        magical items.
      </li>
      <li>
        Exploits alliances to gain exclusive access to critical information,
        including state secrets and private communications.
      </li>
    </ul>
  </div>

  <div class="dossier-section">
    <h2>Current Objectives</h2>
    <p>
      Ongoing investigation suggests the following priority actions to disrupt
      the Blue Baron's operations:
    </p>
    <ul>
      <li>
        <strong>Break:</strong> His alliance with A'bal, head of the Fris Media
        Conglomerate.<br />
      </li>
      <li>
        <strong>Steal:</strong> A cache of secret information stored in his
        private archives to expose his blackmail operations.<br />
      </li>
      <li>
        <strong>Discover:</strong> And dismantle his network of spies planting
        false evidence against key figures in society.<br />
      </li>
    </ul>
  </div>
</div>

<style>
  * {
    box-sizing: border-box;
  }

  .lore-text {
    padding: 30px;
    color: white;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .dossier {
    background-color: #111;
    padding: 20px;
    color: #fff;
    font-family: "Courier New", Courier, monospace;
    border: 1px solid #444;
    max-width: 800px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  }

  .dossier-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #444;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .dossier-header h1 {
    font-size: 1.5em;
    color: #4c88ff;
  }

  .portrait {
    width: 120px;
    height: 120px;
    border: 2px solid #4c88ff;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }

  .portrait img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1.1);
  }

  .dossier-section {
    margin-bottom: 20px;
  }

  .dossier-section h2 {
    color: #4c88ff;
    font-size: 1.2em;
    border-bottom: 1px solid #444;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  .dossier-section p,
  .dossier-section ul {
    margin: 10px 0;
  }

  .dossier-section ul {
    list-style-type: none;
    padding-left: 0;
  }

  .dossier-section ul li::before {
    content: "• ";
    color: #4c88ff;
  }

  @media (max-width: 768px) {
    .dossier-header {
      flex-direction: column;
      align-items: center;
    }
    .portrait {
      margin-top: 10px;
    }
  }
</style>
