<script>
  let mindMap = {
    ideas: [
      { id: "main", title: "Downfall of the Barons", x: 525, y: 250 },
      { id: "red", title: "Red", x: 410, y: 180 },
      {
        id: "red-1",
        title: "Investigate: Rumours of illegal arms deals with enemy states.",
        x: 380,
        y: 50,
      },
      {
        id: "red-2",
        title:
          "Disrupt the Red Baron's fighting tournaments, which act as recruitment for his private army",
        x: 180,
        y: 20,
      },
      {
        id: "red-3",
        title:
          "Expose the exploitation of works in his weapon factories to turn public opinion against him",
        x: 200,
        y: 150,
      },
      { id: "blue", title: "Blue", x: 600, y: 180 },
      {
        id: "blue-1",
        title:
          "Break his alliance with the figure known as A'bal, head of the Fris Media Conglomerate",
        x: 550,
        y: 30,
      },
      {
        id: "blue-2",
        title:
          "Steal a cache of secret information stored in their private archives to expose their blackmail operations",
        x: 740,
        y: 0,
      },
      {
        id: "blue-3",
        title:
          "Discover and dismantle their network of spieswho are planting false evidence against key figures in society",
        x: 880,
        y: 110,
      },
      { id: "green", title: "Green", x: 525, y: 330 },
      {
        id: "green-1",
        title:
          "Discover who has taken control of the Green Baron's drug trade after their mysterious disappearance.",
        x: 360,
        y: 450,
      },
      {
        id: "green-2",
        title:
          "Disrupt underground gambling dens and seize their earnings to cut off funding for the illicit operations.",
        x: 530,
        y: 470,
      },
      {
        id: "green-3",
        title:
          "Investigate: rumors of a new, dangerous drug being circulated; find its source.",
        x: 710,
        y: 480,
      },
      { id: "white", title: "White", x: 700, y: 250 },
      {
        id: "white-1",
        title:
          "Sneak into one of the White Baron's charity galas to uncover financial documents proving embezzlement.",
        x: 710,
        y: 360,
      },
      {
        id: "white-2",
        title:
          "Sabotage the prudction line of their airships to cripple their shipping dominance.",
        x: 880,
        y: 230,
      },
      {
        id: "white-3",
        title:
          "Expose the exploitation of froeign workers in their import/export network to spark outrage among their investors.",
        x: 880,
        y: 360,
      },
      { id: "black", title: "Black", x: 350, y: 250 },
      {
        id: "black-1",
        title:
          "Investigate ties between the Black Baron's investment banks and a criminal organization laundering money.",
        x: 330,
        y: 310,
      },
      {
        id: "black-2",
        title:
          "Sabotage the release of a new, highly addictive stretegy game that is driving many into debt.",
        x: 160,
        y: 270,
      },
      {
        id: "black-3",
        title:
          "Win an invitation to an elite Shadowfey Chess tournament to gather intel on thier inner circle.",
        x: 170,
        y: 410,
      },
    ],
    connections: [
      { from: "red", to: "red-1" },
      { from: "blue", to: "blue-1" },
      { from: "green", to: "green-1" },
      { from: "white", to: "white-1" },
      { from: "black", to: "black-1" },
      { from: "red", to: "red-2" },
      { from: "blue", to: "blue-2" },
      { from: "green", to: "green-2" },
      { from: "white", to: "white-2" },
      { from: "black", to: "black-2" },
      { from: "red", to: "red-3" },
      { from: "blue", to: "blue-3" },
      { from: "black", to: "black-3" },
      { from: "green", to: "green-3" },
      { from: "white", to: "white-3" },
    ],
  };

  let svgWidth = 1200;
  let svgHeight = 600;

  function findIdeaById(id) {
    return mindMap.ideas.find((idea) => idea.id === id);
  }

  function getColorFromId(id) {
    if (id.startsWith("red")) return "red";
    if (id.startsWith("blue")) return "blue";
    if (id.startsWith("green")) return "green";
    if (id.startsWith("white")) return "white";
    if (id.startsWith("black")) return "#555"; // Slightly grey black
    return "#FFD700"; // Default color (white)
  }

  const wrapText = (text, maxWidth) => {
    const words = text.split(" ");
    let lines = [];
    let currentLine = "";

    for (const word of words) {
      const testLine = currentLine ? `${currentLine} ${word}` : word;
      if (testLine.length > maxWidth) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    }

    if (currentLine) {
      lines.push(currentLine);
    }

    return lines;
  };
</script>

<section class="page-container">
  <div class="storyline-intro">
    <h1>Alterra 2025: The Fall of the Barons</h1>
    <p>
      The campaign storyline, "The Fall of the Barons," centers on the five
      Dragonfruit Barons, corrupt capitalists who wield immense power in
      society. Each Baron’s influence is tied to a specific type of dragonfruit
      and a major industry. These Barons have built their fortunes through
      unethical means, including hired killings, intimidation, and illegal
      dealings, leaving society to suffer under their influence.
      <br /><br />
      Taking them down requires more than just defeating them in battle; the players
      must dismantle the very foundations of their power—destroying their businesses
      and cutting off their wealth and resources. Only then can the Barons truly
      be stopped from reclaiming their positions.
    </p>
    <br />
    <h2>The Realm of Eres</h2>
    <p>
      Eres, once home to four powerful city-states, is now a war-torn landscape.
      Akiba, a small trading town, has grown into a city-state and stands as the
      last sanctuary of peace. After three years of war, divine armies have been
      routed, but new threats are emerging, including undead, deadly plants, and
      monstrous creatures. Adventurers are needed to clear these threats and
      reclaim lost territories. But while dangers lurk outside the city of
      Akiba, corruption spreads at the city's core as power is concentrating in
      the hands of the elite.
    </p>
    <br />

    <h2>Meet The Dragonfruit Barons</h2>
    <br />

    <h3>The Red Baron</h3>
    <p>
      Controls weapons manufacturing, including swords, crossbows, armored
      Warhorses, and fighting competitions. His business thrives on conflict and
      chaos. The Red Baron is said to be a gluttonous large man (Human) with a
      quick and violent temper who is constantly followed by several abused
      servants.
    </p>

    <br />
    <h3>The Blue Baron</h3>
    <p>
      Specializes in information brokering, auction houses, and high-end item
      dealing. A master of secrets and leverage, their business trades in the
      power of knowing what others don’t. The Blue Baron is said to be a sharp
      hawk-faced man (Half-Elf) with beady eyes and rectangular spectables. His
      intelligence is matched only by his envy and insecurity.
    </p>
    <br />

    <h3>The Green Baron</h3>
    <p>
      Oversees shady enterprises like the alcohol industry, gambling dens, and
      the illicit drug trade. Their underhanded dealings keep society hooked on
      vices. The Green Baron is a sleezy weasle of a man (Human) known for his
      uncomfortable leering gaze and slicked back hair.
    </p>
    <br />

    <h3>The White Baron</h3>
    <p>
      Dominates import/export industries and funds investment into newly
      developed airships. Their wealth supports opulent events like galas,
      balls, and charity auctions. The face of the group, the White Baron is
      said to be tall, charming, and handsome with a golden voice and a lush of
      blond locks said to be both the desire and envy of maidens across the
      realm.
    </p>
    <br />

    <h3>The Black Baron</h3>
    <p>
      Invests in high-stakes banking and the strategy gaming industry, producing
      games like Shadowfey Chess and organizing exclusive competitions. The
      Black Baron is the least well known among the oligarch thanks to his
      reclusive nature. Rumours suggests that he is often consulted in the
      matter of War Game tatics by several high ranking commanders.
    </p>
  </div>

  <h3>The Path to Victory! (Narrative Objectives)</h3>
  <div class="mind-map-container">
    <svg
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      preserveAspectRatio="xMidYMid meet"
      style="width: 100%; height: 100%;"
    >
      {#each mindMap.connections as { from, to }}
        {#if findIdeaById(from) && findIdeaById(to)}
          <line
            x1={findIdeaById(from).x + 75}
            y1={findIdeaById(from).y +
              (wrapText(findIdeaById(from).title, 40).length * 20 + 20) / 2}
            x2={findIdeaById(to).x + 75}
            y2={findIdeaById(to).y +
              (wrapText(findIdeaById(to).title, 40).length * 20 + 20) / 2}
            stroke={getColorFromId(from)}
            stroke-width="2"
          />
        {/if}
      {/each}

      {#each mindMap.ideas as { id, title, x, y }}
        <g transform={`translate(${x}, ${y})`} class="idea-box">
          <rect
            width="150"
            height={wrapText(title, 30).length * 20 + 20}
            rx="10"
            ry="10"
            fill="#444"
            stroke={getColorFromId(id)}
            stroke-width="2"
          />

          <!-- Render wrapped lines of text -->
          {#each wrapText(title, 25) as line, index}
            <text
              x="75"
              y={index * 15 + 25}
              text-anchor="middle"
              font-size="12"
              fill="white"
            >
              {line}
            </text>
          {/each}
        </g>
      {/each}
    </svg>
  </div>
</section>

<style>
  * {
    box-sizing: border-box;
  }

  * {
    box-sizing: border-box;
  }

  .storyline-intro {
    padding: 20px;
    color: white;
    background-color: #2b2b2b;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  h1,
  h2,
  h3 {
    color: #ffcc00;
  }

  .mind-map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 100%;
    background-color: #2c2f33;
    color: #ffffff;
    overflow: hidden;
  }

  @media (max-width: 768px) {
    .storyline-intro {
      padding: 10px;
    }
  }
</style>
