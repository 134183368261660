<script>
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";
  import { auth, getValidAccessToken } from "../lib/stores/auth";
  import { get } from "svelte/store";

  let authState;

  let activeTab = "reagents";
  let activeFilterReagents = "crystals";
  let activeFilterRecipes = "alchemy";
  let tooltipContent = { name: "", description: "", image: "" };
  let tooltipVisible = false;
  let tooltipPosition = { top: "0px", left: "0px" };

  let selectedPower;
  let selectedBase;
  let selectedExotic;
  let selectedArcanite;
  let selectedCharacter;

  $: reagentsSelected =
    !!selectedPower && !!selectedBase && !!selectedExotic && !!selectedArcanite;

  let reagentsList = [];
  let recipesList = [];
  let baseList = [];
  let powerList = [];
  let characterList = [];

  $: snackbarMessage = null;

  const craftingTypes = ["alchemy", "artificery", "cooking"];
  const reagentTypes = ["crystals", "ore", "plants"];

  function switchTab(tab) {
    activeTab = tab;
  }

  function setFilterReagents(filter) {
    activeFilterReagents = filter;
  }

  function setFilterRecipes(filter) {
    activeFilterRecipes = filter;
  }

  async function fetchReagents() {
    const response = await fetch("/api/reagents");
    const data = await response.json();
    reagentsList = data;
  }

  async function fetchRecipes() {
    const response = await fetch("/api/recipes");
    const data = await response.json();
    recipesList = data;
  }

  async function fetchBaseMaterials() {
    const response = await fetch("/api/materials");
    const data = await response.json();
    baseList = data;
  }

  async function fetchPowerSources() {
    const response = await fetch("/api/powers");
    const data = await response.json();
    powerList = data;
  }

  async function fetchUserCharacters() {
    const token = await getValidAccessToken();
    const response = await fetch(`/api/characters`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const data = await response.json();
    characterList = data;
  }

  onMount(async () => {
    authState = get(auth);
    if (!authState.isAuthenticated) {
      push("/signin");
    } else {
      await Promise.all([
        fetchReagents(),
        fetchRecipes(),
        fetchBaseMaterials(),
        fetchPowerSources(),
        fetchUserCharacters(),
      ]);
      selectedCharacter = characterList[0];
    }
  });

  function showTooltip(event, item) {
    tooltipContent = {
      name: item.name,
      description: item.description,
      image: item?.image || "/media/images/placeholder.png",
      reagents:
        item?.reagent_names?.length > 0
          ? item?.reagent_names?.join(", ")
          : null,
    };

    const rect = event.currentTarget.getBoundingClientRect();
    tooltipPosition = {
      top: `${rect.bottom + window.scrollY}px`,
      left: `${(window.innerWidth / 2 + rect.left) / 2 - 300}px`,
    };

    tooltipVisible = true;
  }

  function hideTooltip() {
    tooltipVisible = false;
  }

  function craftItem() {
    snackbarMessage = "Successfully Crafted!";
    setTimeout(() => {
      snackbarMessage = null;
    }, 3000);
  }

  async function buyRecipe(recipe) {
    try {
      const token = await getValidAccessToken();
      const response = await fetch(
        `/api/characters/${selectedCharacter.id}/inventory/recipes/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(recipe),
        }
      );
      const result = await response.json();
      if (!response.ok) {
        throw new Error("Failed to save changes");
      }
      selectedCharacter.inventory = result.inventory;
    } catch (error) {
      console.error(error);
    }
    snackbarMessage = `Successfully Bought ${recipe.name}!`;
    setTimeout(() => {
      snackbarMessage = null;
    }, 3000);
  }

  async function buyReagent(reagent) {
    try {
      const token = await getValidAccessToken();
      const response = await fetch(
        `/api/characters/${selectedCharacter.id}/inventory/reagents/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(reagent),
        }
      );
      const result = await response.json();
      if (!response.ok) {
        throw new Error("Failed to save changes");
      }
      selectedCharacter.inventory = result.inventory;
    } catch (error) {
      console.error(error);
    }
    snackbarMessage = `Successfully Bought ${reagent.name}!`;
    setTimeout(() => {
      snackbarMessage = null;
    }, 3000);
  }
</script>

<!-- svelte-ignore a11y-mouse-events-have-key-events -->
<slot>
  <div class="page-container">
    <div class="page-header">
      <h1>
        WELCOME {authState?.user?.username.toUpperCase()} TO RAPEL'S ARTIFICERY AND
        CRAFT STUDIO
      </h1>
      <h3>May Artos guide your thoughts and hands.</h3>
    </div>
    <div class="character-header">
      {#if characterList && characterList.length > 0}
        <h3>Select Character:</h3>
        <select
          id="selectedCharacter"
          class="dropdown"
          bind:value={selectedCharacter}
        >
          {#each characterList as character}
            <option value={character}>{character.name}</option>
          {/each}
        </select>
        <label for="selectedCharacter"
          >Now shopping for {selectedCharacter?.name}</label
        >
        <label for="selectedCharacterGold"
          >Available Gold: {selectedCharacter?.inventory?.currency.find(
            (e) => e.item_name == "Gold"
          )?.quantity || 0}</label
        >
      {/if}
    </div>

    <div class="character-creation-container">
      <div class="tab-buttons">
        <button
          class={activeTab === "reagents" ? "active" : ""}
          on:click={() => switchTab("reagents")}
        >
          🧪 Reagents
        </button>
        <button
          class={activeTab === "recipes" ? "active" : ""}
          on:click={() => switchTab("recipes")}
        >
          📖 Recipes / Items
        </button>
        <button
          class={activeTab === "rules" ? "active" : ""}
          on:click={() => switchTab("rules")}
        >
          📜 Rules
        </button>
        <button
          class={activeTab === "craft" ? "active" : ""}
          on:click={() => switchTab("craft")}
        >
          🛠️ Craft
        </button>
      </div>

      <div class="tab-content">
        {#if activeTab === "reagents"}
          <div class="nav-buttons">
            {#each reagentTypes as type}
              <button
                class:active-filter={type === activeFilterReagents}
                on:click={() => setFilterReagents(type)}>{type}</button
              >
            {/each}
          </div>

          <div class="reagents-container">
            {#each reagentsList.filter((r) => r.type === activeFilterReagents) as reagent}
              <div
                class="reagent-card"
                on:mouseover={(e) => showTooltip(e, reagent)}
                on:mouseleave={hideTooltip}
              >
                <img
                  src={reagent?.image
                    ? reagent.image
                    : "/media/images/placeholder.png"}
                  alt={reagent.name}
                  class="reagent-image"
                />
                <span class="reagent-name">{reagent.name}</span>
                <button
                  class="buy-button"
                  disabled={reagent.cost >
                    (selectedCharacter?.inventory?.currency.find(
                      (e) => e.item_name == "Gold"
                    )?.quantity || 0)}
                  on:click={() => buyReagent(reagent)}
                  >BUY ({reagent.cost}gp)</button
                >
              </div>
            {/each}
          </div>
        {/if}

        {#if activeTab === "recipes"}
          <div class="nav-buttons">
            {#each craftingTypes as type}
              <button
                class:active-filter={type === activeFilterRecipes}
                on:click={() => setFilterRecipes(type)}>{type}</button
              >
            {/each}
          </div>

          <div class="crafting-recipes-container">
            {#each recipesList.filter((r) => r.type === activeFilterRecipes) as recipe}
              <div
                class="recipe-card"
                on:mouseover={(e) => showTooltip(e, recipe)}
                on:mouseleave={hideTooltip}
              >
                <img
                  src={recipe?.image
                    ? recipe.image
                    : "/media/images/placeholder.png"}
                  alt={recipe.name}
                  class="recipe-image"
                />
                <span class="recipe-name">{recipe.name}</span>
                <button
                  class="buy-button"
                  disabled={recipe.cost >
                    (selectedCharacter?.inventory?.currency.find(
                      (e) => e.item_name == "Gold"
                    )?.quantity || 0)}
                  on:click={() => buyRecipe(recipe)}
                  >BUY ({recipe.cost}gp)</button
                >
              </div>
            {/each}
          </div>
        {/if}

        {#if activeTab === "rules"}
          <div class="rules-container">
            <h2 class="section-title">Crafting System Overview</h2>

            <h3 class="section-subtitle">Crafting Components</h3>
            <p class="intro-text">
              Here are the key components involved in crafting a magical item:
            </p>
            <ul class="component-list">
              <li class="component-item">
                <strong>1. Base Item:</strong>
                <p>
                  The <strong>first component</strong>. This is the item to be
                  enchanted or used as the base of the magical item, such as a
                  sword, shield, ring, etc. The base item determines the overall
                  shape and purpose of the magic item.
                </p>
              </li>
              <li class="component-item">
                <strong>2. Exotic Ingredient:</strong>
                <p>
                  The <strong>second component</strong>. A rare or unusual
                  material that significantly influences the magical effect of
                  the item. The exotic ingredient aligns with the desired
                  magical effect and makes crafting the magic item easier and
                  cheaper. Examples include:
                </p>
                <ul class="nested-list">
                  <li>
                    <strong>Cinder Crystals</strong>: For fire-based magic
                    (e.g., Flame Tongue Sword).
                  </li>
                  <li>
                    <strong>Celestial Steel</strong>: For protection or radiant
                    effects (e.g., Radiant Shields).
                  </li>
                  <li>
                    <strong>Other Exotic Ingredients</strong>: These materials
                    will define the magical nature of the item, and their use
                    should align with the intended effect of the magic item.
                  </li>
                </ul>
              </li>
              <li class="component-item">
                <strong>3. Arcanite:</strong>
                <p>
                  The <strong>third component</strong>. Arcanite is the conduit
                  that translates the power of the exotic ingredient and the
                  inscribed arcane instructions through the Weave. Different
                  colors of Arcanite can amplify or modifiy the magical effect
                  in different ways, making the magic item more efficient or
                  achieving the desired outcome using different subsystems, but
                  all arcanite serves this fundamental role of conveying the
                  magic to the world.
                </p>
              </li>
              <li class="component-item">
                <strong>4. Energy Source:</strong>
                <p>
                  The <strong>fourth component</strong>. This is the spark that
                  intiatiates the item’s magical effect(s) and creates the
                  pathway through the arcanite to the weave. For most items,
                  this spark should align with the exotic ingredient being used
                  as this will increase the chances of a successful initiation.
                  External sources of power, such as
                  <strong>Divine Blessings</strong>,
                  <strong>Dragon Breath</strong>, or other forms of unique
                  energy, can be used to ignite the item’s enchantment. Once the
                  initial spark is provided, the item can be recharged either by
                  <strong>Soul Energy</strong> (from the user, this process requires
                  the item to be attuned to the user's unique Soul frequency) or
                  by passively gathering energy from the environment.
                </p>
                <p>
                  Some magical items may require <strong
                    >unique charging mechanisms</strong
                  > based on the power source and the type of magic being stored
                  or channeled.
                </p>
              </li>
            </ul>

            <h3 class="section-subtitle">Crafting Cost Breakdown</h3>
            <p>
              The cost of crafting an item is determined by its rarity, material
              requirements, and the type of ingredients and energy used. The
              following is an overview of how crafting costs increase with
              rarity and material complexity as well as the percentage that each
              component takes up of the total cost:
            </p>
            <ul class="left-indent">
              <li>Base Materia: 10%</li>
              <li>Exotic Ingredient: 35%</li>
              <li>Power Source: 35%</li>
              <li>Arcanite: 20%</li>
            </ul>

            <h4 class="pricing-title">Item Rarity Pricing (Market)</h4>
            <table class="pricing-table">
              <thead>
                <tr>
                  <th>Item Rarity</th>
                  <th>Market Price (Gold)</th>
                  <th>Consumable Price (Gold)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Common</td>
                  <td>50</td>
                  <td>25</td>
                </tr>
                <tr>
                  <td>Uncommon</td>
                  <td>500</td>
                  <td>250</td>
                </tr>
                <tr>
                  <td>Rare</td>
                  <td>5,000</td>
                  <td>2,500</td>
                </tr>
                <tr>
                  <td>Very Rare</td>
                  <td>50,000</td>
                  <td>25,000</td>
                </tr>
                <tr>
                  <td>Legendary</td>
                  <td>500,000</td>
                  <td>250,000</td>
                </tr>
              </tbody>
            </table>
          </div>
        {/if}

        {#if activeTab == "craft"}
          <div class="crafting-container">
            <div class="grid-system">
              <div class="grid-item"></div>
              <div class="grid-item power-dropdown">
                <label for="powerSource">Power Source</label>
                <select
                  id="powerSource"
                  class="dropdown"
                  bind:value={selectedPower}
                >
                  <option value="" disabled selected>Power Source</option>
                  {#each powerList as power}
                    <option value={power}>{power.name}</option>
                  {/each}
                </select>
              </div>
              <div class="grid-item"></div>

              <!-- Reagents Dropdown (Left Second Row, excluding Arcanite) -->
              <div class="grid-item reagents-dropdown">
                <label for="reagent">Exotic Material</label>
                <select
                  id="reagent"
                  class="dropdown"
                  bind:value={selectedExotic}
                >
                  <option value="" disabled selected>Exotic Material</option>
                  {#each reagentsList.filter((r) => !r.name.includes("Arcanite")) as reagent}
                    <option value={reagent}>{reagent.name}</option>
                  {/each}
                </select>
              </div>

              <!-- Base Material Dropdown (Middle Second Row) -->
              <div class="grid-item base-material-dropdown">
                <label for="baseMaterial">Base Material</label>
                <select
                  id="baseMaterial"
                  class="dropdown"
                  bind:value={selectedBase}
                >
                  <option value="" disabled selected>Base Material</option>
                  {#each baseList as base}
                    <option value={base}>{base.name}</option>
                  {/each}
                </select>
              </div>

              <!-- Arcanite Dropdown (Right Second Row) -->
              <div class="grid-item arcanite-dropdown">
                <label for="arcanite">Arcanite</label>
                <select
                  id="arcanite"
                  class="dropdown"
                  bind:value={selectedArcanite}
                >
                  <option value="" disabled selected>Arcanite</option>
                  {#each reagentsList.filter( (r) => r.name.includes("Arcanite") ) as arcanite}
                    <option value={arcanite}>{arcanite.name}</option>
                  {/each}
                </select>
              </div>

              <!-- Cost Box (Above Craft Button) -->
              {#if reagentsSelected}
                <div class="grid-item cost-box">
                  <table>
                    <thead>
                      <tr>
                        <th>Category</th>
                        <th>Uncommon</th>
                        <th>Rare</th>
                        <th>Very Rare</th>
                        <th>Legendary</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Base</td>
                        <td>
                          {selectedBase
                            ? `1x ${selectedBase.name} or item cost (average: 50gp)`
                            : ""}
                        </td>
                        <td>
                          {selectedBase
                            ? `1x ${selectedBase.name} or item cost (average: 50gp)`
                            : ""}
                        </td>
                        <td>
                          {selectedBase
                            ? `1x ${selectedBase.name} or item cost (average: 50gp)`
                            : ""}
                        </td>
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>Exotic</td>
                        <td
                          >{selectedExotic
                            ? `1x ${selectedExotic.name} or 175gp`
                            : ""}
                        </td>
                        <td
                          >{selectedExotic
                            ? `10x ${selectedExotic.name} or 1,750gp`
                            : ""}
                        </td>
                        <td
                          >{selectedExotic
                            ? `100x ${selectedExotic.name}  or 17,500gp`
                            : ""}
                        </td>
                        <td>N/A </td>
                      </tr>
                      <tr>
                        <td>Power</td>
                        <td
                          >{selectedPower
                            ? `1x ${selectedPower.name} or 175gp`
                            : ""}
                        </td>
                        <td
                          >{selectedPower
                            ? `10x ${selectedPower.name} or 1,750gp`
                            : ""}
                        </td>
                        <td
                          >{selectedPower
                            ? `100x ${selectedPower.name} or 1,7500gp`
                            : ""}
                        </td>
                        <td>N/A </td>
                      </tr>
                      <tr>
                        <td>Arcanite</td>
                        <td
                          >{selectedArcanite
                            ? "1x Standard Arcanite or 100gp"
                            : ""}</td
                        >
                        <td
                          >{selectedArcanite
                            ? "10x Standard Arcanite or 1x Exotic Arcanite or 1,000gp"
                            : ""}</td
                        >
                        <td
                          >{selectedArcanite
                            ? "100x Standard Arcanite or 10x Exotic Arcanite or 1x Metalic Arcanit or 10,000gpe"
                            : ""}</td
                        >
                        <td>N/A</td>
                      </tr>
                      <tr>
                        <td>Total Cost</td>
                        <td>500gp</td>
                        <td>5,000gp</td>
                        <td>50,000gp</td>
                        <td>N/A</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>
                    * Plus service charge for customisation (determined by the
                    Artificers rates)
                  </p>
                </div>
              {/if}

              <!-- Craft Button (Middle Third Row) -->
              <div class="grid-item craft-button-container">
                <button
                  id="craftButton"
                  class="craft-button"
                  on:click={craftItem}
                  disabled={!reagentsSelected || true}
                >
                  Craft
                </button>
              </div>
            </div>
          </div>
        {/if}
      </div>

      {#if tooltipVisible}
        <div
          class="tooltip"
          style="top: {tooltipPosition.top}; left: {tooltipPosition.left};"
        >
          <h3>{tooltipContent.name}</h3>
          <img src={tooltipContent.image} alt={tooltipContent.name} />
          <p>{tooltipContent.description}</p>
          <br />
          {#if tooltipContent.reagents}
            <p>Required ingredient(s): {tooltipContent.reagents}</p>
          {/if}
        </div>
      {/if}
      {#if snackbarMessage}
        <div id="snackbar">{snackbarMessage}</div>
      {/if}
    </div>
  </div>
</slot>

<style>
  * {
    box-sizing: border-box;
  }

  .page-container {
    justify-content: center;
    justify-items: center;
    align-items: flex-start;
    padding-top: 80px;
    color: #e0e0ff;
  }

  .page-header {
    justify-content: center;
    justify-items: center;
    align-items: flex-start;
  }

  .character-header {
    display: flex;
    width: 80%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
    padding: 16px;
  }

  .character-header h3,
  .character-header label,
  .character-header select {
    max-width: 250px;
    padding: 10px;
    margin: 10px;
    align-items: center;
    align-content: center;
    justify-content: center;
    justify-items: center;
    margin-bottom: 0px;
  }

  .left-indent {
    margin-left: 1.5rem;
  }

  /* Pricing */
  .pricing-title {
    margin-top: 1rem;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .pricing-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 1rem;
    background-color: #f9f9f9;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .pricing-table th,
  .pricing-table td {
    padding: 12px 16px;
    text-align: left;
  }

  .pricing-table th {
    background-color: #4caf50;
    color: white;
    font-size: 1.1rem;
  }

  .pricing-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .pricing-table tbody tr:hover {
    background-color: #ddd;
  }

  .pricing-table td {
    font-size: 1rem;
    color: #555;
  }

  /* Character Creation Container */
  .character-creation-container {
    width: 90%;
    font-family: "Arial", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    padding: 20px;
    background-color: #2a123f;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  }

  .tab-buttons {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .tab-buttons button {
    background-color: #3d1752;
    color: #ffcc70;
    border: none;
    padding: 10px 20px;
    margin: 0 5px;
    cursor: pointer;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    transition:
      background-color 0.3s,
      color 0.3s;
    flex: 1;
    text-align: center;
  }

  .tab-buttons button.active {
    background-color: #ffcc70;
    color: #2a123f;
  }

  .tab-buttons button:hover {
    background-color: #572366;
  }

  .tab-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    padding: 40px;
    background-color: #2a123f;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    border-radius: 0 10px 10px 10px;
  }

  .section-title,
  .section-subtitle {
    color: #ffcc70;
  }

  .intro-text {
    color: #e0e0ff;
  }

  .component-list {
    padding-left: 20px;
    list-style-type: disc;
  }

  .component-item {
    margin-bottom: 15px;
  }

  .nested-list {
    padding-left: 20px;
    list-style-type: circle;
    color: #e0e0ff;
  }

  .component-item strong {
    color: #ffcc70;
  }

  .nav-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }

  .nav-buttons button {
    background-color: #3d1752;
    color: #ffcc70;
    border: none;
    padding: 10px;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .nav-buttons button.active-filter {
    background-color: #ffcc70;
    color: #2a123f;
    font-weight: bold;
  }

  /* Reagents & Crafting Recipes */
  .reagents-container,
  .crafting-recipes-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    width: 100%;
    z-index: 0;
  }

  .reagent-card,
  .recipe-card {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: #3d1752;
    border-radius: 8px;
    font-size: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition:
      transform 0.2s,
      box-shadow 0.2s;
  }

  .reagent-card:hover,
  .recipe-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.4);
  }

  .recipe-image,
  .reagent-image {
    width: 60px;
    height: 60px;
    margin-bottom: 5px;
    border-radius: 50%;
    border: 2px solid #ffcc70;
  }

  .reagent-card img,
  .recipe-card img {
    width: 100%;
    height: 100%;
    margin-left: auto;
    border: 4px solid #ffcc70;
    border-radius: 10px;
    background-color: #2a123f;
    pointer-events: none;
    z-index: 0;
  }

  .recipe-name,
  .reagent-name {
    color: #ffcc70;
    font-weight: bold;
    margin-top: 5px;
  }

  /* Crafting Form */
  .crafting-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
  }

  label {
    font-weight: bold;
    margin-bottom: 8px;
    display: block;
  }

  .dropdown {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }

  .craft-button-container {
    margin-top: 20px;
  }

  .craft-button {
    padding: 15px 30px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .craft-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .buy-button {
    width: 120px;
    margin-top: 10px;
    padding: 10px 5px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 2px;
    font-size: 1rem;
    cursor: pointer;
  }

  .buy-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  /* Tooltip Styling */
  .tooltip {
    position: absolute;
    padding: 15px;
    background-color: #2a123f;
    border: 2px solid #ffcc70;
    border-radius: 8px;
    color: #e0e0ff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    z-index: 100;
    text-align: center;
    width: 600px;
    word-wrap: break-word;
  }

  .tooltip h3 {
    color: #ffcc70;
  }

  .tooltip img {
    width: 60px;
    height: 60px;
    margin: 10px 0;
    border-radius: 5px;
    border: 2px solid #ffcc70;
  }

  .tooltip p {
    color: #e0e0ff;
  }

  /* Rules Container Styling */
  .rules-container {
    width: 100%;
    padding: 20px;
    background-color: #3d1752;
    border-radius: 8px;
    color: #ffcc70;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  }

  h2,
  h3 {
    color: #ffcc70;
  }

  p {
    color: #e0e0ff;
  }

  /* Snackbar */
  #snackbar {
    visibility: visible;
    min-width: 250px;
    margin-left: -125px;
    background-color: #4caf50;
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    bottom: 30px;
    left: 50%;
    font-size: 1rem;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  }

  .grid-system {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto auto auto;
    gap: 16px;
  }

  .grid-item {
    text-align: center;
    margin: 40px;
  }

  .craft-button-container {
    grid-column: 2 / span 1;
  }

  .cost-box {
    grid-column: 1 / span 3;
    margin-bottom: 16px;
  }

  .cost-box table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 8px;
  }

  .cost-box th,
  .cost-box td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }

  @media (max-width: 768px) {
    .pricing-table {
      font-size: 0.9rem;
    }

    .pricing-table th,
    .pricing-table td {
      padding: 8px 12px;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
</style>
