<script>
  let username = "";
  let email = "";
  let password = "";
  let confirmPassword = "";
  let message = "";

  async function handleSignup() {
    if (password !== confirmPassword) {
      message = "Passwords do not match.";
      return;
    }

    const userData = {
      username,
      email,
      password,
      bio: "Profile Bio",
      exp: 0,
      level: 1,
    };

    try {
      const response = await fetch("/api/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });

      if (response.ok) {
        message = "Registration successful. You can now log in.";
        window.location.href = "/signin";
      } else {
        const data = await response.json();
        message = `Registration failed: ${data.detail}`;
      }
    } catch (error) {
      console.error(`Error: ${error}`);
      message = "An error occurred during registration.";
    }
  }
</script>

<div class="signup-container">
  <div class="signup-box">
    <h2>Sign Up</h2>
    <div class="input-container">
      <label for="username">Username</label>
      <input
        id="username"
        type="text"
        autocomplete="username"
        bind:value={username}
        required
      />
      <p class="requirements">
        Required. 150 characters or fewer. Letters, digits and @/./+/-/_ only.
      </p>
    </div>
    <div class="input-container">
      <label for="email">Email Address</label>
      <input
        id="email"
        type="email"
        autocomplete="email"
        bind:value={email}
        required
      />
    </div>
    <div class="input-container">
      <label for="password">Password</label>
      <input id="password" type="password" bind:value={password} required />
      <ul class="ul-requirements">
        <li>Your password must contain at least 8 characters.</li>
        <li>
          Your password can’t be too similar to your other personal information.
        </li>
        <li>Your password can’t be a commonly used password.</li>
        <li>Your password can’t be entirely numeric.</li>
      </ul>
    </div>
    <div class="input-container">
      <label for="confirm-password">Password Confirmation</label>
      <input
        id="confirm-password"
        type="password"
        bind:value={confirmPassword}
        required
      />
      <p class="requirements">
        Enter the same password as before, for verification.
      </p>
    </div>
    <button class="signup-button" on:click={handleSignup}>Sign Up</button>
    {#if message}
      <p class="message">{message}</p>
    {/if}
  </div>
</div>

<style>
  .signup-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 200px;
    margin-bottom: 50px;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .signup-box {
    color: black;
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
  }

  .signup-box h2 {
    font-size: 28px;
    font-family: "Acme", sans-serif;
  }

  .input-container {
    margin-top: 20px;
    text-align: left;
  }

  .input-container label {
    display: block;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 8px;
  }

  .input-container input {
    width: 100%;
    padding: 14px;
    margin-top: 8px;
    border: 3px solid #000;
    border-radius: 30px;
    background-color: #f3f2f2;
    font-family: "Acme", sans-serif;
    font-size: 16px;
  }

  .ul-requirements {
    text-align: left;
    margin-top: 10px;
    font-size: 12px;
    color: #555;
  }

  .signup-button {
    width: 100%;
    background-color: #0078d4;
    color: white;
    padding: 18px 20px;
    border: none;
    border-radius: 50px;
    margin-top: 30px;
    cursor: pointer;
    font-family: "Acme", sans-serif;
    font-size: 16px;
    font-weight: bold;
  }

  .signup-button:hover {
    background-color: #005daf;
  }

  .requirements {
    font-size: 12px;
    color: #666;
    margin-top: 8px;
  }

  .message {
    margin-top: 20px;
    font-size: 14px;
    color: #d9534f;
  }

  /* Mobile responsiveness */
  @media (max-width: 480px) {
    .signup-box h2 {
      font-size: 24px;
    }

    .input-container input {
      padding: 12px;
      font-size: 14px;
    }

    .signup-button {
      font-size: 16px;
      padding: 16px;
    }

    .requirements,
    .ul-requirements {
      font-size: 10px;
    }
  }
</style>
