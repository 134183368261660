<script>
  import { onMount } from "svelte";
  import QuillCreator from "../lib/components/QuillCreator.svelte";
  import { push } from "svelte-spa-router";
  import { auth } from "../lib/stores/auth";
  import { get } from "svelte/store";

  let articleTitle = "";
  let articleCategory = "general";
  let successMessage = false;
  let editor;

  onMount(async () => {
    const authState = get(auth);
    editor = authState.isAuthenticated && authState.user.editor;
    if (!editor) {
      push("/signin");
    }
  });
</script>

<slot>
  <div class="lore-page">
    <div class="lore-sidebar">
      <button on:click={() => push("/lore/category/general/1")}>
        <span class="tooltip">General</span><img
          src="media/images/GeneralBook.png"
          alt="General Book"
        />
      </button>
      <button on:click={() => push("/lore/category/cosmology/1")}>
        <span class="tooltip">Cosmology</span><img
          src="media/images/CosmologyBook.png"
          alt="Cosmology Book"
        />
      </button>
      <button on:click={() => push("/lore/category/realms/1")}>
        <span class="tooltip">Realms</span><img
          src="media/images/RealmsBook.png"
          alt="Realms Book"
        />
      </button>
    </div>

    <div class="lore-content">
      <div class="central-column">
        <div class="col-left">
          <h1>Create a New Article</h1>
          <div>
            <label class="color-white" for="articleTitle"
              >Article Title (*unique)</label
            >
            <input
              id="articleTitle"
              type="text"
              placeholder="Enter the title of the article"
              bind:value={articleTitle}
            />
          </div>
          <div>
            <label class="color-white" for="articleCategory"
              >Article Category</label
            >
            <select
              id="articleCategory"
              bind:value={articleCategory}
              placeholder="General"
            >
              <option value="general">General</option>
              <option value="cosmology">Cosmology</option>
              <option value="realms">Realms</option>
              <option value="storylines">Storylines</option>
              <option value="items">Items</option>
              <option value="bestiary">Bestiary</option>
            </select>
          </div>
          <div class="quil-container">
            <QuillCreator bind:articleTitle bind:articleCategory />
          </div>
        </div>
      </div>
    </div>

    <div class="lore-sidebar">
      <button on:click={() => push("/lore/category/storylines/1")}>
        <span class="tooltip">Storylines</span><img
          src="media/images/StorylinesBook.png"
          alt="Storylines Book"
        />
      </button>
      <button on:click={() => push("/lore/category/items/1")}>
        <span class="tooltip">Items</span><img
          src="media/images/ItemsBook.png"
          alt="Items Book"
        />
      </button>
      <button on:click={() => push("/lore/category/bestiary/1")}>
        <span class="tooltip">Bestiary</span><img
          src="media/images/BestiaryBook.png"
          alt="Bestiary Book"
        />
      </button>
    </div>
  </div>

  {#if successMessage}
    <div class="success-message active">Article Successfully Created!</div>
  {/if}
</slot>

<style>
  .lore-page {
    display: flex;
    justify-content: space-between;
    font-family: "Acme", sans-serif; /* Applied font globally */
  }

  .lore-sidebar {
    flex: 0.5;
    width: 10%;
    margin-top: 160px;
  }

  .color-white {
    color: white !important;
  }

  .success-message {
    background-color: #4caf50; /* Green */
    color: white;
    text-align: center;
    padding: 10px;
    position: fixed;
    top: 25%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%);
    z-index: 9999;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 1s ease;
  }

  .success-message.active {
    opacity: 1;
  }

  button {
    display: block;
    border: none;
    background: none;
    cursor: pointer;
    max-height: 400px;
    max-width: 250px;
    transition: transform 0.3s ease; /* Added transition for button hover effect */
  }

  button:hover {
    transform: scale(1.05); /* Slightly enlarge button on hover */
  }

  button img {
    max-height: 220%;
    max-width: 220%;
    margin-left: -55%;
  }

  .lore-content {
    flex: 3;
    width: 80%;
    margin-top: 100px;
  }

  .quil-container {
    min-height: 500px;
    color: white; /* Sets the text color within the container to white */
  }

  input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2); /* Subtle border for inputs */
    border-radius: 5px; /* Rounded corners for input */
    font-family: "Acme", sans-serif;
  }

  .central-column {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: rgba(215, 181, 148, 0.9); /* Base background color */
    border: 5px solid rgba(188, 151, 96, 1);
    background-image: url("/media/images/papertexture.jpeg"); /* Texture image URL */
    background-size: cover; /* Ensures the texture covers the entire area */
    background-position: center 10%;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
    position: relative; /* Positioning context for overlay */
    color: #333; /* Base text color */
  }

  .central-column::before {
    content: "";
    position: absolute; /* Overlay covers the entire column */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Darker semi-transparent overlay */
    border-radius: 15px; /* Match border radius */
    z-index: 0; /* Place behind text */
  }

  .col-left {
    text-align: center;
    position: relative; /* Positioning context for text */
    z-index: 1; /* Ensure text is above the overlay */
  }

  .col-left h1 {
    font-size: 28px; /* Increased font size for headings */
    margin-bottom: 20px;
    color: #fff; /* White text for headers */
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); /* Subtle shadow for headers */
  }

  .tooltip {
    visibility: hidden;
    width: 120px;
    margin-left: 20px;
    background-color: #1f0620;
    color: #fff;
    text-align: center;
    border-radius: 50px;
    padding: 5px;
    position: absolute;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.3s;
  }

  button:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  @media (max-width: 1050px) {
    /* Add responsive styles here if needed */
  }

  @media (max-width: 900px) {
    /* Add responsive styles here if needed */
  }

  @media (max-width: 768px) {
    /* Add responsive styles here if needed */
  }

  @media (max-width: 568px) {
    .central-column {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .col-left {
      width: 100%;
      text-align: center;
    }

    .lore-sidebar {
      display: none !important;
    }
  }
</style>
