<script>
  import { onMount } from "svelte";
  import Quill from "quill";
  import "quill/dist/quill.snow.css";
  import { getValidAccessToken } from "../stores/auth";

  export let content = "";
  export let onClose;
  export let id;

  let editor;
  $: loading = false;
  let failureMessage = false;

  const handleSave = async () => {
    const html = editor.root.innerHTML;
    loading = true;
    const successfullySaved = await handleSubmit(html);
    loading = false;
    if (successfullySaved) {
      onClose(true);
    } else {
      showFailureMessage();
    }
  };

  function showFailureMessage() {
    failureMessage = true;
    setTimeout(() => {
      failureMessage = false;
    }, 2000);
  }

  async function handleSubmit(html) {
    try {
      const token = await getValidAccessToken();
      const response = await fetch(`/api/wiki-entries/edit/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ content: html }), // Include the content in the request body
      });
      if (!response.ok) {
        throw new Error("Failed to update wiki entry");
      }
      return true;
      // Optionally, handle success (e.g., display a success message or redirect)
    } catch (error) {
      console.error("Error updating wiki entry:", error.message);
      // Optionally, handle error (e.g., display an error message)
    }
  }

  onMount(() => {
    setTimeout(() => {
      failureMessage = false;
    }, 0);
    editor = new Quill("#editor", {
      theme: "snow",
      placeholder: "Got information about Alterra?",
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }], // custom button values
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
          [{ direction: "rtl" }], // text direction
          [{ size: ["small", false, "large", "huge"] }], // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }], // dropdown with defaults from theme
          [{ font: [] }],
          [{ align: [] }],
          ["clean"], // remove formatting button
        ],
      },
    });

    setTimeout(() => {
      const toolbar = document.querySelector(".ql-toolbar.ql-snow");
      if (toolbar) {
        toolbar.classList.add("custom-toolbar");
      }
    }, 0);

    editor.on("text-change", () => {
      const html = editor.root.innerHTML;
    });

    editor.root.innerHTML = content;
  });

  $: if (editor && editor.root.innerHTML !== content) {
    editor.root.innerHTML = content;
  }
</script>

<div class="editor-container">
  <button class="green" on:click={handleSave}
    >{loading ? `SAVING...` : `SAVE`}</button
  >
  <button class="red" on:click={() => onClose(false)}>CANCEL</button>
  <div id="editor"></div>
  {#if failureMessage}
    <div class="failure-message active">
      Artical Failed - Please Contact Admin!
    </div>
  {/if}
</div>

<style>
  #editor {
    height: auto;
    border: 1px solid #ccc;
  }

  .editor-container {
    position: relative;
  }

  :global(.custom-toolbar) {
    background-color: white !important;
  }

  .failure-message {
    background-color: #af4c4c; /* Green */
    color: white;
    text-align: center;
    padding: 10px;
    position: fixed;
    top: 25%; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%);
    z-index: 9999;
    border-radius: 5px;
    opacity: 0;
    transition: opacity 1s ease;
  }

  .failure-message.active {
    opacity: 1;
  }

  button {
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  .green {
    background-color: #4caf50;
  }

  .green:hover {
    background-color: #45a049;
  }

  .red {
    background-color: #af4c4c;
  }

  .red:hover {
    background-color: #8a2e2e;
  }
</style>
