<div class="lore-text">
  This file contains all known information on the Green Baron.
</div>

<div class="dossier">
  <div class="dossier-header">
    <h1>Dossier: Green Baron</h1>

    <div class="portrait">
      <img src="media/images/placeholder.png" alt="Green Baron Portrait" />
    </div>
  </div>

  <div class="dossier-section">
    <h2>Profile</h2>
    <p>
      <strong>Name:</strong> The Green Baron<br />
      <strong>Age:</strong> 49<br />
      <strong>Appearance:</strong> Scrawny and weaselly, with an uncomfortable leering
      gaze and slicked-back hair. His presence exudes greed and sleaze.
    </p>
    <p>
      The Green Baron is a master manipulator, specializing in the darker vices
      of society. Through his enterprises in alcohol production, gambling dens,
      and the illicit drug trade, he perpetuates cycles of addiction and
      servitude. His methods are ruthless, preying on the vulnerable and
      desperate, forcing them into dependence on his wares and trapping them in
      enforced labor. His experiments with poisons and addictive substances
      extend to the exploitation of enslaved creatures and forbidden magics.
      Despite his cunning, the Green Baron was recently discredited and forced
      into hiding, with rumors suggesting a pact with the Archfey Sakura.
    </p>
  </div>

  <div class="dossier-section">
    <h2>Public Enterprises</h2>
    <p>
      Although his businesses appear legitimate, they conceal a darker reality:
    </p>
    <ul>
      <li>
        <strong>Emerald Distilleries:</strong> Producers of fine spirits and liquors,
        secretly laced with addictive substances to ensure dependence.
      </li>
      <li>
        <strong>Verdant Gaming Halls:</strong> Underground gambling dens that exploit
        the poor, using rigged games and debt traps to fund illegal operations.
      </li>
      <li>
        <strong>Hidden Botanical Facility:</strong> A massive farm for dragonfruit
        and other rare, dangerous plants, guarded by dragons and accessed via teleportation
        sigils.
      </li>
    </ul>
  </div>

  <div class="dossier-section">
    <h2>Criminal Activities</h2>
    <p>
      The Green Baron's operations extend far beyond the boundaries of legality:
    </p>
    <ul>
      <li>
        Conducts experiments on creatures, including baby green dragons, rats, a
        revenant, and spiders, in a city-center warehouse overseen by a
        disguised bone devil.
      </li>
      <li>
        Uses narcotic-dependent workers in his factories to produce alcohol and
        drugs, exploiting the slums for cheap labor and enforced servitude.
      </li>
      <li>
        Employs a teleportation sigil network to manage supply lines to hidden
        facilities where enslaved creatures cultivate dangerous plants.
      </li>
      <li>
        Disrupted planar boundaries near Highgarden to expand his operations
        eastward and secure control over weaker planes.
      </li>
      <li>
        Engages in high-stakes gambling at elite country clubs to forge
        alliances and undermine rivals like the Red Baron.
      </li>
    </ul>
  </div>

  <div class="dossier-section">
    <h2>Current Objectives</h2>
    <p>
      To dismantle the Green Baron's influence, the following priorities have
      been identified:
    </p>
    <ul>
      <li>
        <strong>Discover:</strong> Who has taken control of the Green Baron's
        drug trade following his mysterious disappearance.<br />
      </li>
      <li>
        <strong>Disrupt:</strong> Underground gambling dens and seize their
        earnings to cut off funding for illicit operations.<br />
      </li>
      <li>
        <strong>Investigate:</strong> Rumors of a new, dangerous drug being
        circulated and identify its source.<br />
      </li>
    </ul>
  </div>
</div>

<style>
  * {
    box-sizing: border-box;
  }

  .lore-text {
    padding: 30px;
    color: white;
    text-align: center;
    font-family: "Courier New", Courier, monospace;
  }

  .dossier {
    background-color: #111;
    padding: 20px;
    color: #fff;
    font-family: "Courier New", Courier, monospace;
    border: 1px solid #444;
    max-width: 800px;
    margin: 20px auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  }

  .dossier-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #444;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .dossier-header h1 {
    font-size: 1.5em;
    color: #4cff4c;
  }

  .portrait {
    width: 120px;
    height: 120px;
    border: 2px solid #4cff4c;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
  }

  .portrait img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transform: scale(1.1);
  }

  .dossier-section {
    margin-bottom: 20px;
  }

  .dossier-section h2 {
    color: #4cff4c;
    font-size: 1.2em;
    border-bottom: 1px solid #444;
    padding-bottom: 5px;
    margin-bottom: 10px;
  }

  .dossier-section p,
  .dossier-section ul {
    margin: 10px 0;
  }

  .dossier-section ul {
    list-style-type: none;
    padding-left: 0;
  }

  .dossier-section ul li::before {
    content: "• ";
    color: #4cff4c;
  }

  @media (max-width: 768px) {
    .dossier-header {
      flex-direction: column;
      align-items: center;
    }
    .portrait {
      margin-top: 10px;
    }
  }
</style>
